import { FC } from 'react';
import IconProps from './IconProps';

const ViewIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className} p-[2px]`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8572 3.14286H3.14288V3.14286L3.14288 7.14287H20.8572L20.8572 3.14286ZM3.14288 2C2.51169 2 2.00002 2.51168 2.00002 3.14286V7.14287V7.14294H2V8.2858L2 11.7142L2 12.857L2 16.2856L2 17.4284L2 20.8573C2 21.4884 2.51167 22.0001 3.14286 22.0001H6.57143H7.71429H11.1429H12.2857H16.2858H17.4286H20.8572C20.9361 22.0001 21.0131 21.9921 21.0875 21.9769C21.6083 21.8703 22 21.4095 22 20.8573L22 17.4284V16.2856V12.857V11.7142L22 8.2858V7.14294L22.0001 7.14287V3.14286C22.0001 2.51168 21.4884 2 20.8572 2H3.14288ZM20.8572 11.7142L20.8572 8.2858H17.4286V11.7142H20.8572ZM16.2858 11.7142V8.2858H12.2857V11.7142H16.2858ZM12.2857 12.857H16.2858V16.2856H12.2857V12.857ZM11.1429 11.7142V8.2858H7.71429V11.7142H11.1429ZM7.71429 12.857H11.1429V16.2856H7.71429V12.857ZM6.57143 11.7142V8.2858H3.14286L3.14286 11.7142H6.57143ZM3.14286 12.857H6.57143V16.2856H3.14286L3.14286 12.857ZM3.14286 17.4284V20.8573H6.57143V17.4284H3.14286ZM7.71429 17.4284V20.8573H11.1429V17.4284H7.71429ZM12.2857 17.4284V20.8573H16.2858V17.4284H12.2857ZM17.4286 17.4284V20.8573H20.8572L20.8572 17.4284H17.4286ZM20.8572 12.857H17.4286V16.2856H20.8572L20.8572 12.857Z"
        fill="CurrentColor"
      />
    </svg>
  );
};

export default ViewIcon;
